body {
  background-color: #fff;
  padding: 20px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #fff;
  text-align: center;
  padding: 0;
}
ul.header li a, h1 a {
  color: #EEE;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

h1 a {
  color: #111;
  font-size: .7em;
}

h1 a:hover {
  text-decoration: none;
  color:#999;
}

.content{
   width: 80%;
   margin: auto;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  margin-top: 80%;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 25px;
  margin-right: -250%;
  color: black;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  margin-left: -250%;
  color: black;
}

.content div img {
  margin: 0 auto;
  width: 75%;
}

.carousel-indicators {
  display: none;
}

.content li a {
  color: #EEE;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}

.content li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

.content {
  background-color: #FFF;
  text-align: center;
}
.content h2 {
  padding: 0;
  margin: 0;
}

.sub-content {
  background-color: #FFF;
  padding: 20px;
}
.sub-content h2 {
  padding: 0;
  margin: 0;
}


img {
  height: 100%;
  width: 100%;
}

.active {
  color: #111 !important;
}

.content li {
  margin-bottom: 10px;
}


/*MOBILE SHIT*/
@media only screen and (max-width: 600px) {
  
}
